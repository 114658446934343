import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 1279.000000 1280.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,3264.000000) scale(0.100000,-0.100000)">
        <path d="M20687 27949 c-69 -5 -129 -15 -146 -24 -16 -8 -68 -19 -115 -24 -47
-6 -121 -21 -164 -35 -43 -13 -111 -29 -151 -34 -40 -6 -84 -18 -98 -27 -14
-10 -55 -23 -90 -31 -36 -7 -79 -22 -96 -32 -18 -10 -59 -25 -91 -31 -32 -7
-79 -24 -104 -37 -25 -13 -53 -24 -62 -24 -10 0 -28 -8 -41 -19 -13 -10 -49
-26 -79 -36 -30 -9 -73 -27 -95 -40 -22 -12 -54 -26 -72 -29 -18 -4 -43 -16
-56 -26 -13 -10 -48 -30 -78 -44 -30 -14 -61 -30 -69 -35 -8 -6 -33 -19 -55
-29 -67 -32 -135 -71 -169 -97 -18 -14 -40 -25 -48 -25 -8 0 -23 -9 -34 -21
-10 -11 -34 -27 -54 -35 -19 -8 -44 -23 -55 -33 -11 -10 -37 -24 -58 -31 -21
-6 -45 -22 -53 -35 -9 -13 -36 -34 -60 -45 -24 -12 -56 -35 -71 -52 -16 -16
-46 -39 -68 -50 -22 -11 -44 -27 -48 -34 -4 -8 -21 -21 -37 -29 -16 -8 -40
-25 -53 -38 -12 -12 -40 -35 -61 -52 -21 -16 -50 -40 -65 -52 -14 -13 -37 -32
-51 -43 -59 -49 -189 -165 -232 -208 -7 -8 -25 -26 -40 -40 -16 -15 -28 -31
-28 -37 0 -6 -13 -19 -30 -28 -16 -10 -30 -24 -30 -30 0 -7 -24 -32 -53 -57
-28 -24 -54 -49 -56 -55 -2 -5 -18 -24 -35 -40 -17 -17 -44 -47 -60 -68 -15
-21 -46 -57 -67 -80 -21 -23 -41 -50 -45 -59 -3 -10 -17 -31 -32 -47 -15 -16
-40 -48 -57 -70 -16 -23 -42 -56 -56 -74 -14 -18 -34 -47 -43 -64 -9 -18 -21
-33 -27 -33 -5 0 -19 -21 -30 -47 -11 -27 -26 -52 -33 -58 -7 -5 -25 -28 -39
-50 -15 -22 -45 -65 -67 -95 -22 -30 -42 -68 -46 -85 -3 -16 -18 -50 -33 -75
-46 -78 -60 -104 -86 -156 -14 -28 -34 -59 -44 -68 -11 -10 -22 -29 -26 -44
-3 -14 -19 -53 -34 -86 -16 -34 -31 -74 -35 -90 -4 -17 -15 -41 -25 -55 -9
-14 -23 -45 -31 -69 -7 -23 -19 -47 -27 -53 -7 -7 -21 -37 -29 -68 -9 -31 -27
-76 -40 -101 -13 -25 -26 -70 -30 -100 -4 -30 -15 -72 -26 -94 -11 -21 -28
-69 -38 -107 -10 -38 -22 -75 -27 -82 -13 -16 -31 -90 -38 -159 -4 -31 -14
-74 -24 -95 -10 -21 -24 -87 -31 -148 -7 -60 -18 -126 -25 -145 -22 -59 -39
-350 -37 -650 2 -294 5 -323 44 -362 33 -33 66 -38 263 -38 193 0 332 12 369
32 11 6 96 17 190 24 130 11 177 18 201 33 18 10 52 22 76 26 60 9 120 26 144
41 11 7 47 17 80 24 33 6 75 20 93 31 19 11 55 24 80 30 26 5 75 23 110 40 34
16 67 29 74 29 6 0 30 11 52 24 23 13 57 29 77 35 20 6 51 23 69 36 19 14 41
25 50 25 8 0 32 11 53 26 21 14 56 32 79 40 22 8 46 21 52 28 6 8 30 24 54 36
23 12 42 26 42 30 0 4 19 15 42 25 23 9 53 24 67 34 14 10 37 22 51 26 14 4
41 22 60 39 20 17 51 42 69 56 19 14 50 39 70 56 20 17 47 35 60 39 41 13 143
95 270 218 22 20 44 37 50 37 5 0 12 9 16 19 3 11 12 21 20 23 15 3 124 97
165 141 14 15 74 78 133 140 59 62 110 122 113 134 3 12 15 25 25 28 10 4 19
10 19 14 0 5 17 27 38 49 46 49 112 141 112 156 0 6 10 16 22 21 13 6 25 19
26 29 2 11 18 34 36 51 53 54 86 95 86 108 0 6 16 32 35 56 19 24 35 48 35 52
0 5 16 29 35 55 19 25 35 51 35 58 0 7 11 23 25 36 14 13 25 28 25 34 0 6 11
27 24 46 12 19 28 51 35 71 6 20 22 46 35 58 14 13 27 34 30 47 4 13 20 45 36
71 17 26 30 55 30 64 0 10 5 21 11 25 6 4 25 34 41 68 16 33 38 77 48 96 11
19 19 44 20 56 0 11 11 35 24 53 14 18 30 56 36 83 5 28 19 61 30 75 10 13 24
50 30 81 7 31 20 66 31 77 10 11 23 45 30 75 6 30 20 71 30 91 10 20 23 67 30
105 7 38 21 83 31 101 10 17 25 73 34 123 8 50 21 101 29 114 9 13 21 80 30
160 9 77 22 151 31 168 17 34 29 141 39 368 3 80 13 172 20 205 11 48 12 82 4
165 -5 58 -14 168 -18 245 -8 140 -17 177 -47 189 -19 7 -102 6 -257 -5z"/>
<path d="M20400 22360 c-63 -4 -146 -12 -185 -18 -38 -6 -120 -14 -182 -17
-61 -4 -140 -13 -175 -21 -110 -26 -212 -43 -258 -43 -25 -1 -61 -10 -81 -20
-19 -10 -72 -26 -116 -34 -44 -9 -98 -24 -119 -35 -22 -11 -75 -25 -119 -32
-44 -7 -91 -20 -105 -30 -14 -9 -54 -20 -90 -24 -36 -3 -87 -17 -115 -30 -27
-14 -75 -30 -105 -36 -30 -7 -67 -20 -82 -31 -14 -10 -58 -26 -97 -34 -39 -9
-73 -20 -76 -25 -3 -5 -31 -16 -63 -25 -31 -9 -77 -27 -102 -40 -25 -13 -67
-28 -95 -35 -27 -6 -63 -20 -80 -30 -16 -10 -46 -21 -65 -25 -19 -4 -57 -18
-85 -30 -27 -13 -72 -30 -98 -39 -26 -9 -58 -23 -70 -30 -12 -8 -44 -20 -71
-26 -27 -6 -77 -24 -110 -40 -33 -17 -69 -30 -79 -30 -10 0 -30 -9 -45 -20
-15 -10 -49 -24 -75 -30 -26 -6 -70 -22 -96 -35 -26 -14 -74 -30 -105 -35 -31
-5 -73 -18 -94 -29 -20 -11 -74 -27 -120 -36 -46 -9 -98 -25 -115 -35 -18 -10
-54 -21 -82 -25 -27 -4 -83 -17 -123 -31 -62 -21 -90 -24 -200 -23 -121 0
-152 4 -267 29 -27 6 -98 17 -156 25 -68 9 -123 23 -155 38 -27 14 -78 28
-114 32 -41 4 -73 14 -85 25 -11 10 -45 23 -75 28 -30 6 -81 24 -114 41 -32
17 -65 31 -72 31 -8 0 -36 11 -62 25 -26 14 -56 25 -67 25 -11 0 -51 16 -89
35 -42 21 -85 35 -106 35 -25 0 -45 8 -62 23 -16 15 -50 29 -89 37 -35 7 -71
19 -80 26 -8 8 -40 23 -70 33 -30 11 -81 31 -114 45 -33 14 -73 26 -88 26 -15
0 -40 9 -55 20 -16 12 -55 28 -88 36 -32 9 -72 24 -89 34 -16 10 -52 24 -80
31 -27 7 -64 21 -80 31 -17 9 -61 22 -99 27 -37 5 -76 17 -86 26 -10 9 -58 24
-106 34 -49 10 -102 25 -119 33 -16 9 -75 23 -130 32 -55 10 -104 21 -110 25
-14 12 -135 41 -167 41 -15 0 -55 9 -90 20 -47 15 -123 25 -298 36 -367 24
-1012 7 -1134 -31 -27 -8 -90 -20 -140 -26 -50 -6 -140 -24 -201 -39 -60 -15
-130 -31 -153 -34 -24 -4 -60 -15 -80 -25 -21 -10 -75 -24 -121 -30 -60 -8
-91 -17 -106 -31 -12 -11 -32 -20 -46 -20 -31 0 -116 -26 -143 -43 -12 -8 -43
-19 -71 -26 -27 -7 -63 -21 -80 -31 -16 -10 -52 -24 -80 -30 -27 -7 -66 -23
-85 -35 -19 -13 -51 -26 -70 -30 -35 -8 -210 -90 -220 -104 -3 -4 -24 -13 -47
-19 -24 -7 -55 -24 -69 -37 -15 -14 -35 -25 -44 -25 -10 0 -28 -9 -42 -19 -14
-11 -45 -28 -69 -37 -24 -9 -55 -27 -69 -39 -14 -12 -44 -30 -67 -40 -24 -10
-43 -22 -43 -26 0 -5 -16 -15 -36 -24 -20 -8 -49 -26 -66 -40 -16 -14 -35 -25
-42 -25 -6 0 -26 -13 -44 -30 -18 -16 -40 -30 -48 -30 -7 0 -31 -16 -53 -35
-21 -19 -43 -35 -48 -35 -5 0 -20 -11 -33 -25 -13 -14 -29 -25 -36 -25 -11 0
-47 -27 -122 -90 -13 -11 -37 -29 -55 -42 -17 -12 -40 -31 -49 -40 -10 -10
-21 -18 -26 -18 -4 0 -45 -37 -92 -81 -47 -44 -96 -86 -110 -94 -14 -7 -53
-43 -88 -79 -35 -36 -65 -66 -68 -66 -13 0 -310 -311 -345 -363 -15 -21 -36
-45 -48 -53 -11 -8 -21 -20 -21 -25 0 -6 -15 -25 -32 -43 -53 -53 -88 -96 -88
-107 0 -6 -24 -34 -53 -63 -57 -55 -137 -157 -137 -173 0 -5 -9 -18 -21 -29
-12 -10 -28 -36 -36 -57 -9 -21 -23 -43 -33 -50 -9 -6 -25 -27 -34 -45 -9 -18
-23 -35 -30 -38 -8 -3 -20 -26 -27 -52 -7 -26 -23 -55 -36 -65 -13 -10 -23
-23 -23 -29 0 -10 -62 -109 -95 -152 -8 -11 -22 -41 -30 -67 -9 -25 -25 -55
-35 -64 -11 -10 -20 -24 -20 -32 0 -8 -18 -51 -40 -95 -22 -45 -40 -87 -40
-93 0 -7 -8 -19 -18 -29 -11 -9 -25 -35 -32 -59 -8 -23 -22 -50 -31 -59 -10
-10 -20 -28 -24 -40 -8 -26 -65 -151 -100 -218 -13 -25 -26 -63 -30 -85 -4
-22 -18 -58 -30 -80 -13 -22 -28 -66 -34 -98 -6 -32 -19 -70 -30 -85 -10 -15
-24 -53 -29 -83 -6 -31 -22 -72 -35 -92 -13 -19 -27 -56 -30 -82 -4 -26 -15
-59 -26 -74 -11 -15 -26 -67 -35 -120 -9 -51 -21 -102 -27 -112 -5 -10 -18
-66 -29 -124 -11 -57 -26 -117 -33 -132 -8 -15 -24 -75 -36 -133 -12 -58 -28
-134 -36 -170 -8 -36 -19 -111 -25 -167 -5 -56 -18 -137 -29 -180 -11 -43 -25
-141 -31 -218 -7 -77 -18 -165 -25 -195 -7 -30 -18 -161 -25 -290 -6 -129 -15
-291 -18 -360 -9 -170 3 -1012 17 -1185 6 -77 18 -176 25 -220 8 -44 20 -170
25 -280 7 -127 18 -229 30 -280 10 -44 24 -129 30 -190 6 -60 18 -139 26 -175
8 -36 22 -117 31 -180 9 -63 27 -155 40 -205 13 -49 28 -130 34 -180 6 -49 19
-115 30 -147 11 -31 24 -98 31 -150 6 -51 19 -109 29 -128 10 -19 22 -65 28
-101 6 -37 21 -91 35 -120 14 -31 28 -83 31 -124 4 -41 15 -82 27 -101 11 -18
24 -66 30 -115 6 -46 19 -101 30 -121 10 -21 25 -67 34 -103 8 -36 21 -78 29
-93 7 -15 21 -56 30 -92 9 -36 25 -83 36 -105 10 -22 24 -71 30 -110 6 -38 19
-81 29 -95 10 -14 21 -43 25 -65 4 -22 21 -70 37 -107 16 -36 29 -75 29 -85 0
-10 12 -40 25 -68 14 -27 30 -75 36 -105 7 -30 22 -68 34 -85 12 -16 25 -50
29 -75 3 -25 17 -64 30 -87 13 -23 27 -57 31 -75 4 -18 17 -51 30 -73 12 -22
28 -65 35 -95 7 -30 21 -64 32 -76 10 -12 21 -39 25 -60 7 -44 29 -94 42 -94
5 0 14 -21 21 -47 7 -27 23 -64 36 -83 13 -20 27 -51 30 -70 4 -19 18 -51 31
-70 12 -19 23 -42 23 -51 0 -20 28 -88 44 -109 6 -8 18 -33 25 -55 7 -22 21
-49 31 -60 9 -11 24 -41 34 -67 9 -25 23 -52 31 -58 8 -6 21 -36 30 -65 8 -30
20 -57 26 -61 6 -4 22 -32 36 -63 14 -31 30 -63 38 -71 7 -8 15 -27 18 -41 4
-14 17 -41 30 -60 12 -19 31 -55 41 -81 10 -27 23 -51 30 -55 6 -5 18 -27 25
-50 8 -23 25 -52 37 -64 13 -13 24 -29 24 -36 0 -8 11 -29 24 -46 13 -18 31
-49 39 -69 9 -21 25 -47 36 -60 12 -12 24 -36 27 -54 3 -18 13 -39 21 -45 8
-7 24 -31 35 -53 11 -23 31 -54 44 -69 13 -16 24 -35 24 -42 0 -7 11 -27 25
-43 13 -16 34 -50 44 -76 11 -25 25 -46 31 -46 5 0 10 -6 10 -13 0 -15 91
-147 115 -167 8 -7 15 -20 15 -29 0 -9 16 -36 35 -60 19 -24 35 -50 35 -57 0
-7 10 -21 22 -31 13 -10 31 -36 41 -58 10 -21 27 -45 38 -53 10 -7 19 -19 19
-26 0 -7 11 -28 25 -47 14 -19 48 -69 76 -111 27 -43 54 -78 59 -78 5 0 17
-14 26 -32 9 -18 23 -38 30 -46 8 -7 23 -32 33 -55 11 -23 29 -48 40 -55 12
-7 21 -18 21 -26 0 -7 12 -27 28 -44 15 -17 43 -52 62 -77 19 -25 46 -60 60
-78 14 -17 32 -44 39 -59 8 -15 22 -30 32 -33 9 -3 20 -16 23 -28 3 -12 21
-40 41 -62 19 -22 35 -45 35 -51 0 -6 11 -21 25 -34 14 -13 25 -28 25 -32 0
-10 15 -29 133 -171 37 -45 77 -95 90 -110 12 -15 36 -43 52 -62 37 -42 44
-50 86 -106 19 -25 57 -68 84 -96 28 -28 93 -97 145 -153 52 -56 117 -123 144
-150 26 -26 52 -52 57 -56 5 -5 44 -45 88 -89 43 -44 118 -111 166 -150 48
-38 103 -85 123 -103 19 -17 41 -32 49 -32 7 0 13 -7 13 -15 0 -8 15 -22 33
-31 17 -9 52 -37 77 -61 25 -24 63 -54 84 -65 22 -11 53 -33 69 -49 16 -16 34
-29 41 -29 7 0 30 -11 50 -25 20 -14 48 -28 61 -31 14 -3 31 -14 38 -23 6 -10
33 -26 60 -36 26 -10 47 -21 47 -25 0 -4 26 -18 58 -31 31 -12 68 -31 81 -41
13 -10 29 -18 35 -18 6 0 43 -15 81 -34 39 -18 92 -36 119 -40 27 -3 57 -12
65 -20 19 -16 70 -32 131 -41 25 -4 72 -16 105 -28 58 -21 75 -22 430 -22 347
0 376 1 460 22 50 12 135 27 189 33 57 6 119 19 145 31 25 11 74 25 108 30 34
5 81 19 106 32 25 13 71 27 104 31 40 4 71 15 93 31 19 14 48 25 65 25 17 0
54 12 81 26 27 14 72 32 99 41 28 8 71 26 97 39 26 13 54 24 61 24 8 0 39 13
70 30 31 16 64 30 73 30 10 0 36 11 59 25 24 13 64 32 91 41 27 9 56 22 64 30
9 7 42 20 75 29 33 8 71 23 85 33 14 10 54 24 90 32 36 7 79 21 95 31 17 9 53
23 80 30 28 6 66 20 85 29 19 9 61 22 92 29 31 7 69 22 83 33 22 17 50 23 132
30 58 5 124 16 146 24 60 23 156 44 200 44 20 0 70 9 110 21 105 30 413 42
852 34 320 -6 365 -9 410 -26 27 -11 93 -24 145 -30 52 -6 120 -22 150 -34 35
-15 88 -26 145 -30 60 -5 101 -13 124 -26 18 -11 65 -26 103 -34 39 -9 82 -24
97 -34 14 -10 50 -24 79 -30 29 -6 63 -18 76 -26 12 -8 47 -22 77 -31 30 -9
63 -21 74 -28 11 -7 45 -21 75 -31 30 -10 73 -28 96 -41 22 -13 47 -24 55 -24
8 0 45 -16 83 -35 39 -19 76 -35 85 -35 8 0 33 -11 55 -24 23 -12 64 -29 91
-35 28 -7 64 -21 81 -32 31 -19 50 -26 214 -83 41 -14 87 -33 101 -41 15 -8
60 -22 100 -30 41 -8 97 -24 124 -35 28 -11 89 -24 137 -29 48 -6 104 -19 125
-29 48 -25 158 -42 269 -42 47 0 134 -8 193 -17 146 -23 388 -23 506 -1 50 10
137 22 195 28 68 7 118 17 143 30 20 11 70 25 110 31 39 6 87 20 106 30 19 11
56 24 82 29 51 10 310 131 344 160 11 10 38 23 61 29 22 6 51 22 64 36 13 14
31 25 41 25 9 0 22 7 30 16 7 8 33 26 58 39 25 12 55 32 65 44 11 12 23 21 28
21 10 0 66 45 136 108 20 17 40 32 46 32 5 0 21 11 35 24 68 63 95 86 102 86
15 0 167 136 238 214 99 108 148 159 259 271 50 51 92 100 92 107 0 8 11 21
25 28 13 7 27 21 30 31 3 9 17 24 30 33 14 9 25 21 25 27 0 10 28 46 108 139
17 20 32 44 32 52 0 8 11 20 25 26 14 6 25 16 25 21 0 6 15 26 33 45 55 60 87
102 101 137 8 19 24 40 35 47 12 7 21 18 21 26 0 7 15 29 33 49 49 55 76 92
92 131 9 20 20 36 25 36 5 0 17 15 26 33 9 17 43 62 75 99 33 36 59 76 59 87
0 11 9 25 20 31 11 6 27 24 35 40 8 16 26 40 40 54 13 13 27 36 31 50 3 14 17
32 30 41 13 8 24 21 24 27 0 6 10 22 22 35 12 13 31 38 42 57 12 19 42 62 69
97 26 35 47 68 47 74 0 6 16 34 35 61 19 27 35 55 35 62 0 6 11 22 24 33 14
12 31 38 39 58 8 20 24 45 36 55 12 11 21 25 21 32 0 6 15 30 33 52 19 22 40
54 47 71 7 17 16 31 20 31 4 0 10 10 14 23 3 12 19 38 36 57 16 18 34 48 39
65 5 17 20 43 35 58 14 15 29 40 32 56 4 17 15 37 25 47 10 9 25 38 34 64 9
26 25 56 35 65 11 10 20 23 20 30 0 6 13 29 29 51 15 21 33 53 39 71 6 17 19
40 31 52 11 11 24 35 29 53 5 18 21 50 35 71 14 21 29 52 33 70 3 17 17 45 29
62 32 43 95 171 95 194 0 11 11 32 24 48 13 15 29 45 36 67 7 21 23 54 36 74
13 19 24 39 24 45 0 6 15 40 34 76 18 36 37 80 40 98 4 17 13 37 21 43 7 6 19
34 26 63 6 29 22 64 35 79 12 15 25 42 29 60 4 18 20 60 36 93 16 33 29 66 29
73 0 7 11 34 24 60 13 26 27 69 31 94 4 26 18 61 31 79 13 17 28 56 34 85 6
29 18 58 26 65 8 6 21 37 30 68 9 31 25 73 36 95 11 21 24 66 29 100 6 33 17
71 24 83 21 31 45 103 45 134 0 31 -78 109 -108 109 -10 0 -27 8 -36 19 -9 10
-38 27 -63 36 -26 10 -62 29 -81 42 -19 12 -44 23 -55 23 -11 0 -34 11 -52 24
-17 13 -47 31 -66 40 -19 9 -61 34 -94 56 -33 22 -79 49 -102 61 -23 11 -48
28 -55 38 -7 10 -30 24 -51 30 -21 7 -45 24 -54 37 -8 13 -19 24 -24 24 -5 0
-29 16 -55 35 -25 19 -50 35 -56 35 -6 0 -26 14 -45 30 -18 17 -38 30 -43 30
-5 0 -20 10 -33 23 -29 28 -61 54 -112 92 -22 17 -46 36 -53 43 -7 6 -18 12
-23 12 -5 0 -25 15 -44 33 -19 17 -50 46 -69 62 -109 95 -267 246 -300 287
-21 25 -84 95 -141 155 -160 168 -163 172 -170 193 -4 11 -10 20 -16 20 -5 0
-20 17 -34 38 -14 20 -46 62 -70 92 -25 30 -54 72 -65 93 -11 20 -24 37 -28
37 -5 0 -19 18 -31 40 -13 23 -34 53 -47 67 -13 14 -24 32 -24 39 0 7 -11 25
-24 41 -27 32 -58 81 -84 133 -9 20 -27 47 -40 60 -12 13 -28 42 -34 65 -6 22
-20 49 -29 60 -10 12 -24 36 -31 55 -6 19 -24 55 -40 79 -15 25 -28 50 -28 56
0 6 -11 26 -24 44 -13 18 -27 48 -31 67 -3 19 -17 51 -30 70 -14 20 -28 59
-31 86 -4 29 -16 61 -29 76 -12 15 -27 50 -34 77 -7 28 -19 61 -28 74 -8 13
-22 56 -30 95 -8 39 -23 85 -33 103 -11 18 -22 61 -26 95 -3 35 -17 90 -29
123 -27 69 -45 151 -45 201 0 19 -9 60 -20 92 -11 31 -25 104 -31 162 -6 58
-17 123 -25 145 -26 71 -39 343 -38 805 1 419 2 450 22 530 24 94 42 226 42
308 0 34 7 67 21 93 11 23 27 78 35 124 8 46 23 104 35 129 11 25 24 81 29
125 5 48 17 92 29 112 11 19 26 58 33 89 6 30 19 65 29 78 9 12 23 46 30 75 7
28 20 63 30 77 10 14 25 54 34 90 8 35 19 67 24 70 14 8 41 68 41 88 0 11 9
31 20 45 11 14 27 43 34 64 7 21 25 56 40 77 14 21 26 44 26 50 0 7 10 26 23
44 13 17 30 50 38 74 7 24 22 50 32 58 20 17 56 74 87 136 11 23 27 47 36 55
8 7 24 30 35 50 10 21 30 49 43 62 13 13 34 44 46 67 23 46 24 46 70 101 16
20 30 44 30 52 0 9 8 18 18 22 11 3 24 17 31 31 11 24 24 41 99 119 17 19 32
39 32 44 0 5 29 39 65 75 36 36 65 71 65 78 0 7 10 18 22 23 13 6 29 22 36 38
7 15 18 27 23 27 13 0 39 25 39 37 0 5 13 17 29 26 17 10 33 26 36 37 3 10 15
22 26 25 11 4 25 18 31 31 6 13 19 26 29 29 10 3 21 15 24 26 4 11 19 27 33
36 15 10 36 26 48 38 11 11 41 38 67 60 26 22 50 48 53 57 3 9 15 20 27 24 12
4 41 23 64 43 22 20 64 54 92 75 28 22 51 43 51 48 0 8 57 48 122 85 22 12 48
34 59 48 11 14 26 25 33 25 7 0 23 11 36 25 13 14 28 25 34 25 6 0 34 18 61
40 28 21 60 47 73 57 21 16 22 22 13 66 -5 31 -17 54 -30 63 -11 8 -21 21 -21
29 0 12 -39 63 -99 130 -10 11 -26 35 -35 53 -9 18 -24 36 -32 39 -7 3 -14 11
-14 19 0 7 -13 23 -30 36 -16 12 -30 27 -30 34 0 7 -11 21 -25 32 -13 11 -42
41 -63 68 -21 27 -54 65 -72 84 -18 19 -39 45 -48 58 -8 12 -21 22 -28 22 -8
0 -14 4 -14 9 0 19 -315 321 -334 321 -6 0 -24 13 -40 30 -52 49 -100 90 -107
90 -4 0 -21 12 -37 28 -65 61 -94 82 -127 96 -19 8 -38 22 -41 31 -3 8 -12 15
-19 15 -8 0 -35 18 -62 40 -99 81 -111 90 -125 90 -8 0 -30 11 -49 25 -69 52
-105 75 -139 91 -19 9 -50 29 -68 45 -19 17 -44 29 -60 29 -15 0 -40 8 -57 17
-16 9 -52 28 -80 44 -109 60 -444 221 -494 238 -19 6 -44 11 -56 11 -12 0 -44
12 -70 26 -27 14 -70 29 -97 34 -27 6 -61 18 -74 29 -13 11 -56 25 -96 31 -39
7 -85 21 -102 31 -18 10 -61 23 -96 28 -36 5 -94 20 -130 34 -36 14 -94 28
-130 32 -36 4 -85 16 -110 27 -29 13 -85 23 -160 29 -124 11 -176 19 -285 42
-38 9 -173 21 -300 27 -126 6 -295 18 -374 26 -79 8 -158 13 -175 12 -17 -1
-83 -4 -146 -8z"/>

        </g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
